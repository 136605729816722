@import 'f-select.css';
:root {
    --bg-primary: #14147B;
    --bg-secondary: #F5F5F7;
    --bg-header: #F0F0F2;
    --bg-main: #fff;
    --text-light: #797A7B;
    --text-dark: #000;
    --placeholder-color: #797A7B;
    --h-pra-color: #2E2E2E;
    --default-radius: 8px;
    --card-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
    --card-bg: #FCFCFC;
    --bg-input: #F0F0F2;
    --input-color: #2E2E2E;
    --placeholder-font: 'Fira Code', monospace;
}

body,
html {
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

body {
    position: relative;
    background: var(--bg-main);
    font-family: 'Inter', sans-serif;
    color: var(--text-dark);
}

p {
    font-weight: 400;
    font-size: 14px;
}

h1,
h2,
h3 {
    font-weight: 700;
}

.btn-primary {
    background: var(--bg-primary);
    border-color: var(--bg-primary);
    color: #fff;
}

.btn-disconnect {
    background: var(--bg-main);
    border-color: var(--bg-main);
    border-radius: var(--default-radius);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #C20A0A;
}

.btn-disconnect:hover {
    background: #E3E5E9;
    border-color: #E3E5E9;
    color: #C20A0A;
}

.btn-disconnect img {
    width: 22px;
    margin-right: 5px;
}

.wallet-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.wallet-info img {
    margin-right: 5px;
    width: 30px;
}

.wallet-info span {
    width: 220px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.app-button-sec {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: auto;
    padding: 10px 15px;
    left: 0;
}

.btn-download {
    background: #fff;
    border-radius: var(--default-radius);
    border: 1px solid #d8d8d8;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    color: #2E2E2E;
    width: 100%;
    padding: 8px 25px;
    margin-bottom: 7px;
}

.btn-download img {
    margin-right: 15px;
    width: 26px;
}

.btn-download span.b-cont {
    text-align: left;
}

.btn-download span.available {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(46, 46, 46, 0.6);
}

.btn-download:hover {
    background: #E3E5E9;
}

.connect-modal {
    backdrop-filter: blur(2px);
    background-color: rgb(46 46 46 / 3%);
}

.connect-modal .modal-dialog {
    max-width: 425px !important;
}

.connect-modal .modal-dialog .modal-content {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background: #fff;
}

.connect-modal .modal-dialog .modal-content .modal-header {
    padding: 18px 22px 0px 22px;
}

.connect-modal .modal-dialog .modal-content .modal-header .btn-close,
.sidebar-container .sidebar-header .close-menu {
    width: 37px;
    height: 37px;
    background: #F0F0F2;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    color: #2E2E2E;
    font-size: 29px;
}

.connect-modal .modal-dialog .modal-content .modal-body {
    padding: 10px 50px 20px 50px;
}

.connect-modal .modal-dialog .modal-content .modal-body h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}

.connect-modal .modal-dialog .modal-content .modal-body .qr-scan-img img {
    width: 217px;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
}

.connect-modal .modal-dialog .modal-content .modal-body .qr-content-sec p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.connect-modal .modal-dialog .modal-inner-content .modal-body .download-link ul li a img {
    max-width: 142px;
}

.connect-modal .modal-dialog .modal-content .modal-body .wallet-scan-img img {
    width: 217px;
    height: auto;
    margin: auto;
    margin-bottom: 20px;
}

.connect-modal.send-modal .modal-content .modal-header .send-icon {
    width: 94px;
    height: 94px;
    background: var(--bg-primary);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.connect-modal.send-modal .modal-content .modal-header .send-icon img {
    width: 60px;
}

.connect-modal.send-modal .modal-content .modal-body {
    padding: 25px;
}

.connect-modal.send-modal .modal-content .modal-body h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-top: 30px;
}

.connect-modal.send-modal .modal-content .modal-body p {
    font-weight: 500;
    text-align: left;
    color: #797A7B;
}

.connect-modal.send-modal .modal-content .modal-body p.top {
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
    color: #2E2E2E;
}

.connect-modal.send-modal .modal-content .modal-body p.wallet-address {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 25px;
    color: #2E2E2E;
    max-width: 340px;
    word-break: break-all;
    font-family: var(--placeholder-font);
}

.connect-modal.send-modal .modal-content .modal-body .sending-amount ul li span.amount {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.06em;
    color: #2E2E2E;
    font-family: var(--placeholder-font);
}

.connect-modal.send-modal .modal-content .modal-body .sending-amount ul li .coin-name img {
    width: 17px;
    height: auto;
    margin-right: 5px;
}

.connect-modal.send-modal .modal-content .modal-body .sending-amount ul li span.coin-n {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #797A7B;
}

.connect-modal.send-modal .modal-content .modal-body .confirmation-sec .btn-cancel {
    background: #F0F0F2;
    border-color: #F0F0F2;
    color: #2E2E2E;
    padding: 15px 40px;
    width: 40%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
}

.connect-modal.send-modal .modal-content .modal-body .confirmation-sec .btn-primary {
    padding: 15px 40px;
    width: 50%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 20px;
}

.scale-100 {
    transform: translate(0, 0) rotate(0) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.sidebar-container {
    position: fixed;
    background: var(--bg-secondary);
    border-right: 1px dashed rgba(46, 46, 46, 0.4);
    top: 0;
    left: 0;
    width: 290px;
    height: 100%;
    z-index: 99;
    overflow-y: auto;
}

.sidebar-container::-webkit-scrollbar {
    width: 7px;
    height: 8px;
}

.sidebar-container::-webkit-scrollbar-track-piece {
    background-color: #eaeaea;
}

.sidebar-container::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: #989898;
}

.sidebar-container::-webkit-scrollbar-track {
    background-color: #C2D2E4;
}

.sidebar-container::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #989898;
}

.sidebar-container .sidebar-header {
    padding: 34px 32px 16px 32px;
}

.sidebar-container .sidebar-header .close-menu {
    position: absolute;
    top: 15px;
    right: 15px;
    border: 1px solid #d8d8d8;
    background: #fff;
}

.sidebar-container .sidebar-header-history {
    padding: 32px;
    display: none;
}

.sidebar-container .sidebar-header-history.active {
    display: block;
}

.sidebar-container .sidebar-header-history .Add-detail {
    margin-bottom: 20px;
}

.sidebar-container .sidebar-header-history .add-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2E2E2E;
}

.sidebar-container .sidebar-header-history .add {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #797A7B;
}

.sidebar-container .sidebar-header-history .u-amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000;
}

.sidebar-container .sidebar-header .company-logo img {
    max-width: 85px;
    margin: auto;
}

.sidebar-container .sidebar-header .company-title h1 {
    font-size: 16px;
    line-height: 19px;
    color: var(--text-dark);
}

.sidebar-container .sidebar-header .btn span {
    font-size: 14px;
}

.sidebar-container .sidebar-navigation {
    border-top: 1px dashed rgba(46, 46, 46, 0.4);
    height: calc(100vh - 310px);
    overflow: hidden;
    overflow-y: auto;
}

.sidebar-container .sidebar-navigation::-webkit-scrollbar {
    width: 7px;
    height: 8px;
}

.sidebar-container .sidebar-navigation::-webkit-scrollbar-track-piece {
    background-color: #eaeaea;
}

.sidebar-container .sidebar-navigation::-webkit-scrollbar-thumb:vertical {
    height: 30px;
    background-color: #989898;
}

.sidebar-container .sidebar-navigation::-webkit-scrollbar-track {
    background-color: #C2D2E4;
}

.sidebar-container .sidebar-navigation::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #989898;
}

.sidebar-container .sidebar-navigation ul {
    padding: 30px 15px;
}

.sidebar-container .sidebar-navigation ul li a {
    color: var(--text-light);
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    background: transparent;
    border-radius: var(--default-radius);
    padding: 15px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    text-decoration: none;
    cursor: pointer;
}

.sidebar-container .sidebar-navigation ul li a span:before,
.sidebar-container .sidebar-navigation ul li a span:before {
    font-size: 20px;
}

.sidebar-container .sidebar-navigation ul li a:hover,
.sidebar-container .sidebar-navigation ul li a.active {
    background: #E3E5E9;
    color: var(--bg-primary);
}

.sidebar-container .sidebar-navigation ul li a:hover span:before,
.sidebar-container .sidebar-navigation ul li a.active span:before {
    color: var(--dark);
}

.main-content-container .home-body {
    position: relative;
    top: 0;
    padding: 30px;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
}

.home-body .scan-connect {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-body .scan-connect .compnay-logo img {
    width: 85px;
    height: auto;
    margin-bottom: 40px;
}

.home-body .scan-connect .company-title h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px;
}

.home-body .scan-connect .company-title p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2E2E2E;
    margin-bottom: 30px;
}

.home-body .scan-connect .btn {
    max-width: 220px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    padding: 16px 22px;
}

.main-content-container {
    position: relative;
    padding-left: 290px;
}

.main-content-container .content-header {
    background: var(--bg-header);
    backdrop-filter: blur(2px);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    border-bottom: 1px dashed rgba(46, 46, 46, 0.4);
    z-index: 9;
}

.main-content-container .content-header .wallet-status {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #2E2E2E;
    margin-left: 320px;
}

.main-content-container .content-header .wallet-status span {
    display: inline;
    background: #0BD738;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 7px;
}

.main-content-container .content-body {
    position: relative;
    top: 60px;
    padding: 0px 30px 0px 80px;
    height: auto;
    min-height: calc(100vh - 60px);
    overflow: hidden;
    overflow-y: auto;
}

.main-content-container .content-body .send-form-sec {
    padding-top: 40px;
    padding-bottom: 40px;
}

.main-content-container .content-body h1 {
    font-size: 30px;
    line-height: 36px;
    color: var(--text-dark);
    margin-bottom: 25px;
}

.main-content-container .content-body .content-card {
    background: var(--card-bg);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
    border-radius: var(--default-radius);
    width: 525px;
    padding: 30px 30px;
}

.main-content-container .content-body .content-card .form-group {
    margin-bottom: 30px;
}

.main-content-container .content-body .content-card .form-group label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
}

.main-content-container .content-body .content-card .form-group input.form-control {
    background: var(--bg-input);
    border-radius: var(--default-radius);
    border: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--input-color);
    font-family: var(--placeholder-font);
}

.main-content-container .content-body .content-card .form-group .input.form-control::placeholder {
    color: var(--placeholder-color);
    font-family: var(--placeholder-font) !important;
    font-weight: 400;
}

.main-content-container .content-body .content-card .form-group .btn {
    height: 50px;
    line-height: 40px;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0px;
}

.main-content-container .content-body .token-section {
    position: relative;
    top: 0;
    right: 0;
    border-left: 1px dashed rgba(46, 46, 46, 0.4);
    height: 100%;
    min-height: calc(100vh - 60px);
}

.main-content-container .content-body .token-section .token-header {
    padding: 20px 25px;
    border-bottom: 1px dashed rgba(46, 46, 46, 0.4);
}

.main-content-container .content-body .token-section .token-header .account-balance img {
    width: 45px;
    height: auto;
}

.main-content-container .content-body .token-section .token-header .account-balance p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #797A7B;
    margin-bottom: 5px;
}

.main-content-container .content-body .token-section .token-header .account-balance .amount-info {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
}

.main-content-container .content-body .token-section .token-body {
    padding: 30px 30px 30px 30px;
}

.main-content-container .content-body .token-section h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #2E2E2E;
}

.main-content-container .content-body .token-section ul.token-nav {
    margin-top: 15px;
    margin-bottom: 15px;
}

.main-content-container .content-body .token-section ul.token-nav li {
    background: #EDEDEF;
    border-radius: var(--default-radius);
    padding: 13px 18px;
    width: 100%;
    max-width: 300px;
    cursor: pointer;
}

.main-content-container .content-body .token-section ul.token-nav li div {
    flex-wrap: wrap;
    gap: 12px;
    grid-gap: 12px;
}

.main-content-container .content-body .token-section ul.token-nav li img {
    width: 24px;
    height: 24px;
}

.main-content-container .content-body .token-section ul.token-nav li span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.main-content-container .content-body .token-section .empty-token {
    background: url('https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/token_empty_img.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.main-content-container .content-body .token-section .empty-token img {
    width: 70px;
    height: auto;
}

.main-content-container .content-body .token-section .empty-token .no-token-hd {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #2E2E2E;
}

.main-content-container .content-body.history-body {
    padding: 50px 60px;
}

.history-content h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
}

.history-content .row {
    border-top: 1px solid rgba(46, 46, 46, 0.1);
    padding: 20px 15px;
    margin: 0;
}

.history-content .row .h-content p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.05em;
    color: #797A7B;
    font-family: var(--placeholder-font);
    margin-bottom: 5px;
}

.history-content .row .h-content p.time {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2E2E2E;
    font-family: 'Inter', sans-serif;
}

.history-content .row .h-content .transaction-c {
    background: #F0F0F2;
    border-radius: 100px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.05em;
    padding: 7px 10px;
    text-transform: uppercase;
    color: #2E2E2E;
    font-family: var(--placeholder-font);
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.history-content .row .h-content .transaction-c a {
    text-decoration: none;
    color: #2E2E2E;
    font-family: var(--placeholder-font);
    display: block;
    width: 100%;
}

.history-content .row .h-content .transaction-c.t-hash:hover {
    background-color: var(--bg-primary);
    color: white !important;
}

.history-content .row .h-content .transaction-c.t-hash a:hover {
    color: white !important;
}

#menu-open {
    display: none;
}

#menu-open.active {
    background: var(--bg-primary) !important;
}

#menu-open.active i {
    color: #fff !important;
}

@media (max-width: 1270px) {
    .main-content-container .content-body .content-card {
        width: 100%;
    }
    .wallet-info span {
        width: 170px;
        overflow: hidden;
        font-size: 12px;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 1000px) {
    .history-content .row .h-content .transaction-c {
        font-size: 12px;
    }
}

@media (max-width: 992px) {
    .main-content-container .content-body {
        padding: 0px;
    }
    .main-content-container .content-body .send-form-sec {
        padding: 20px;
    }
    .main-content-container .content-body .token-section {
        border-top: 1px dashed rgba(46, 46, 46, 0.4);
        border-left: 0;
        min-height: auto !important;
        padding: 30px 20px;
    }
    .main-content-container .content-body.history-body {
        padding: 40px;
    }
    .wallet-info span {
        width: 100px;
        overflow: hidden;
        font-size: 12px;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@media (max-width: 767px) {
    #menu-open {
        display: block;
    }
    .wallet-info img {
        width: 24px;
    }
    .btn-disconnect {
        font-size: 12px;
    }
    .btn-disconnect img {
        width: 16px;
    }
    .main-content-container {
        padding-left: 0;
    }
    .sidebar-container {
        transition: 0.3s ease-out;
        left: -100%;
        width: 100%;
    }
    .sidebar-container.active {
        left: 0;
    }
    .main-content-container .content-header .wallet-status {
        margin-left: 0px;
        margin-right: 30px
    }
    .main-content-container .content-body h1 {
        margin-bottom: 15px;
    }
    .history-content .row {
        padding: 10px 0px;
    }
    .history-content .row .h-content.time-c {
        display: flex;
        align-items: center;
    }
    .history-content .row .h-content.time-c p {
        width: auto;
        float: left;
        margin-right: 20px;
    }
    .history-content .row .h-content p {
        margin-bottom: 7px;
    }
    .connect-modal.send-modal .modal-content .modal-body .confirmation-sec .btn-cancel {
        padding: 15px 10px;
        width: 35%;
    }
    .connect-modal.send-modal .modal-content .modal-body .confirmation-sec .btn-primary {
        padding: 15px 10px;
        width: 45%;
    }
}

@media (max-width: 575px) {
    .content-header.xs-header {
        position: relative;
        top: 60px;
        z-index: 8;
    }
    .wallet-info {
        display: none !important;
    }
    .content-header.xs-header .wallet-info {
        display: flex !important;
    }
    .wallet-info span {
        width: 150px;
    }
}

.main-content-container.connect-wallet {
    position: relative;
    text-align: center;
    /*background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
    color: white;*/
    background-image: url('https://mwancloud.com/mw-najeeb-work/Connect-wallet/images/tech_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.waves.waves-top {
    position: absolute;
    width: 100%;
    height: 20vh;
    top: 0;
    left: 0;
    transform: rotate(180deg);
}

.waves.waves-bottom {
    position: absolute;
    width: 100%;
    height: 20vh;
    bottom: 0;
    left: 0;
}

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}

@media (max-width: 992px) {
    .waves.waves-top,
    .waves.waves-bottom {
        height: 15vh;
    }
}

.transaction-detail {
    width: 560px !important;
    /*transform: translateY(-100%) !important;*/
}

.transaction-detail .offcanvas-header {
    padding: 30px;
}

.transaction-detail .offcanvas-header button {
    width: 37px;
    height: 37px;
    line-height: 39px;
    border-radius: 50%;
    background: #F0F0F2;
    font-size: 25px;
    padding: 0;
    opacity: 1;
}

.transaction-detail .offcanvas-header button:hover {
    opacity: .7;
}

.transaction-detail .offcanvas-header h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}

.transaction-detail .offcanvas-body {
    padding: 0;
}

.transaction-detail .offcanvas-body .hash-detail {
    padding: 30px;
}

.transaction-detail .offcanvas-body .hash-detail h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--bg-primary);
    margin-bottom: 10px;
    margin-top: 30px;
}

.transaction-detail .offcanvas-body .hash-detail p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-default);
    word-break: break-all;
    margin-bottom: 15px;
}

.transaction-detail .offcanvas-body .amount-send {
    border-top: 1px solid rgba(46, 46, 46, 0.1);
    border-bottom: 1px solid rgba(46, 46, 46, 0.1);
    padding: 30px;
}

.transaction-detail .offcanvas-body .amount-send h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-light);
}

.transaction-detail .offcanvas-body .amount-send .coin-details {
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.06em;
    text-transform: uppercase;
    color: var(text-default);
}

.transaction-detail .offcanvas-body .amount-send .coin-details span.token {
    font-weight: 500;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-light);
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
}

.transaction-detail .offcanvas-body .amount-send .coin-details span.token img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
}

.transaction-detail .offcanvas-body .all-detail {
    padding: 30px;
}

.transaction-detail .offcanvas-body .all-detail .row {
    padding-top: 8px;
    padding-bottom: 8px;
}

.transaction-detail .offcanvas-body .all-detail .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-light);
}

.transaction-detail .offcanvas-body .all-detail .descp {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-break: break-all;
    color: var(--text-default);
}

@media (max-width: 767px) {
    .transaction-detail {
        width: 100% !important;
    }
}