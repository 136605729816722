.custom-loader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #766DF4);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 16px), #000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}

.loading {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.custom-loader1 {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: conic-gradient(#0000 10%, #766DF4);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}