.f-dropdown {
  --max-scroll: 3;
  position: relative;
  z-index: 10;
}
.f-dropdown select {
  display: none;
}
.f-dropdown > span {
  cursor: pointer;
  padding: 12px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
  color: #000;
  border: none;
  background: #F0F0F2;
  transition: color 0.2s ease, border-color 0.2s ease;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
}
.f-dropdown > span > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
}
.f-dropdown > span img {
  width: 24px;
  margin-right: 10px;
}
.f-dropdown > span:before, .f-dropdown > span:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 2px;
  border-radius: 1px;
  top: 50%;
  right: 12px;
  background: #000;
  transition: all 0.3s ease;
}
.f-dropdown > span:before {
  margin-right: 4px;
  transform: scale(0.96, 0.8) rotate(50deg);
}
.f-dropdown > span:after {
  transform: scale(0.96, 0.8) rotate(-50deg);
}
.f-dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  max-height: calc(var(--max-scroll) * 46px);
  top: 50px;
  left: 0;
  z-index: 1;
  right: 0;
  background: #fff;
  border: none;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  transform-origin: 0 0;
  transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
  transform: translate(0, 5px);
  box-shadow: 0px 2px 16px rgb(0 0 0 / 4%);
  padding: 5px;
}

.f-dropdown ul::-webkit-scrollbar-track {
  background: #00003e;
}

.f-dropdown ul::-webkit-scrollbar {
  width: 6px;
  background: #b71f23;
}

.f-dropdown ul::-webkit-scrollbar-button,
.f-dropdown ul::-webkit-scrollbar-thumb {
  background-color: #b71f23;
}

.f-dropdown ul li {
  padding: 0 !important;
  margin: 0 !important;
}
.f-dropdown ul li a {
  cursor: pointer;
  display: block;
  padding: 10px 10px !important;
  color: #000;
  text-decoration: none;
  outline: none;
  position: relative;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 5px;
  border-radius: 8px !important;
}
.f-dropdown ul li a img {
  width: 24px;
  margin-right: 10px;
}
.f-dropdown ul li a:hover {
  color: #5C6BC0;
}
.f-dropdown ul li.active a, 
.f-dropdown ul li:hover a {
  color: #000 !important;
  background: #E3E5E9;
}
.f-dropdown ul li.active a:before, .f-dropdown ul li.active a:after {
  --scale: 0.6;
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  position: absolute;
  right: 12px;
  top: 50%;
  opacity: 0;
  background: #FFF;
  transition: all 0.2s ease;
  display: none;
}
.f-dropdown ul li.active a:before {
  transform: rotate(45deg) scale(var(--scale));
}
.f-dropdown ul li.active a:after {
  transform: rotate(-45deg) scale(var(--scale));
}
.f-dropdown ul li.active a:hover:before, .f-dropdown ul li.active a:hover:after {
  --scale: 0.9;
  opacity: 1;
}
/*.f-dropdown ul li:first-child a {
  border-radius: 0;
}
.f-dropdown ul li:last-child a {
  border-radius: 0;
}*/
.f-dropdown.disabled {
  opacity: 0.7;
}
.f-dropdown.disabled > span {
  cursor: not-allowed;
}
.f-dropdown.filled > span {
  color: #000;
}
.f-dropdown.open {
  z-index: 15;
}
.f-dropdown.open > span {
  border-color: #AAA;
}
.f-dropdown.open > span:before, .f-dropdown.open > span:after {
  background: #000;
}
.f-dropdown.open > span:before {
  transform: scale(0.96, 0.8) rotate(-50deg);
}
.f-dropdown.open > span:after {
  transform: scale(0.96, 0.8) rotate(50deg);
}
.f-dropdown.open ul {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 12px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s cubic-bezier(0.4, 0.6, 0.5, 1.32);
}

/* --------------------------- */
.f-group {
  max-width: 220px;
  margin: 0;
  text-align: left;
}
.f-group select {
  width: 100%;
}

.f-control {
  font-size: 14px;
  line-height: normal;
  color: #000;
  display: inline-block;
  background-color: #ffffff;
  border: #ccc 1px solid;
  border-radius: 6px;
  padding: 8px 12px;
  outline: none;
  max-width: 250px;
}

.f-control label {
  width: 100%;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}


@media (max-width: 767px) {
  .f-control {
    max-width: 100%;
  }
  
  .f-group {
	  max-width: 100%;
  }
}