.walletconnect-modal__mobile__toggle,
.walletconnect-qrcode__text,
.walletconnect-modal__footer {
    display: none !important;
}

.walletconnect-qrcode__image {
    width: 250px !important;
    height: 250px !important;
}

.walletconnect-modal__base {
    width: 280px !important;
    margin-right: 20px;
}

.walletconnect-modal__header img,
.walletconnect-modal__header p {
    display: none !important;
}

.walletconnect-qrcode__image {
    padding: 7px !important;
}