@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?u9zxo4');
  src:  url('fonts/icomoon.eot?u9zxo4#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?u9zxo4') format('truetype'),
    url('fonts/icomoon.woff?u9zxo4') format('woff'),
    url('fonts/icomoon.svg?u9zxo4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-connect:before {
  content: "\e900";
  color: #fff;
}
.icon-history:before {
  content: "\e901";
  color: #797a7b;
}
.icon-send:before {
  content: "\e902";
  color: #797a7b;
}
.icon-setting:before {
  content: "\e903";
  color: #797a7b;
}
